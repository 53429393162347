const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
export default {
  data() {
    return {};
  },

  methods: {
    checkLogin(selectedLanguage) {
      window.languageUri = selectedLanguage;
      window.language = selectedLanguage;

      if (selectedLanguage == 'pt') {
        window.menuBottomStart = 'Inicio';
        window.menuBottomMenu = 'Menu';
      } else if (selectedLanguage == 'en') {
        window.menuBottomStart = 'Home';
        window.menuBottomMenu = 'Menu';
      } else if (selectedLanguage == 'fr') {
        window.menuBottomStart = 'Accueil';
        window.menuBottomMenu = 'Menu';
      } else if (selectedLanguage == 'es') {
        window.menuBottomStart = 'Entrada';
        window.menuBottomMenu = 'Menú';
      }

      var f7 = this.$f7;
      var destiny = '';
      var userId = urlParams.get('id');
      destiny = '/'; //'/ementa/' + userId + '/' + window.tempTypeId + '/' + window.tempMenuId + '/';

      f7.views.main.router.navigate(destiny);
    }

  },
  id: 'fdf2a5a706',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page">\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner">\n				<div class="left">\n					<a href="/?';
      r += c(ctx_1.userId, ctx_1);
      r += '" class="link icon-only panel-close"  ';
      r += c(ctx_1.styleHome, ctx_1);
      r += '>\n						<i class="material-icons">home</i>\n						<span class="tabbar-label"></span>\n					</a>\n					<a href="#" class="link back" ';
      r += c(ctx_1.styleBack, ctx_1);
      r += '>\n						<i class="icon icon-back"></i>\n						<span class="if-not-md" style="transform: translate3d(0px, 0px, 0px);">Voltar</span>\n					</a>\n					<a href="#" class="link icon-only panel-open" data-panel="left" style="display:none;">\n						<i class="icon f7-icons if-not-md">menu</i>\n						<i class="icon material-icons if-md">menu</i>\n					</a>\n				</div>\n				<div class="title sliding">';
      r += c(ctx_1.restaurantName, ctx_1);
      r += '<span class="subtitle">';
      r += c(ctx_1.categoryName, ctx_1);
      r += '</span></div>\n				<div class="right" style="';
      r += c(ctx_1.menuLanguage, ctx_1);
      r += '">\n					<div class="menu-item menu-item-dropdown">\n						<div class="menu-item-content"><img src="static/images/';
      r += c(ctx_1.language, ctx_1);
      r += '.png" alt="';
      r += c(ctx_1.languageDescription, ctx_1);
      r += '" height="20"></div>\n						<div class="menu-dropdown menu-dropdown-right">\n						  <div class="menu-dropdown-content">\n							';
      r += c(ctx_1.languageMenu, ctx_1);
      r += '\n						  </div>\n						</div>\n					</div>\n				</div>\n			</div>\n		</div>\n		<div class="page-content">\n			';
      r += c(ctx_1.products, ctx_1);
      r += '\n		</div>\n		<div class="toolbar toolbar-bottom tabbar tabbar-labels">\n			<div class="toolbar-inner">\n				<a href="/" class="tab-link panel-close">\n					<i class="fas fa-home"></i>\n					<span class="tabbar-label">';
      r += c(ctx_1.menuBottomStart, ctx_1);
      r += '</span>\n				</a>\n				<a href="/ementa/';
      r += c(ctx_1.userId, ctx_1);
      r += '/category/0/" class="tab-link panel-close ';
      r += c(ctx_1.tab_1, ctx_1);
      r += '">\n					<i class="fas fa-book-open"></i>\n					<span class="tabbar-label">';
      r += c(ctx_1.menuBottomMenu, ctx_1);
      r += '</span>\n				</a>\n				<a href="/wines/';
      r += c(ctx_1.userId, ctx_1);
      r += '/0/" class="tab-link panel-close ';
      r += c(ctx_1.tab_2, ctx_1);
      r += '">\n					<i class="fas ';
      r += c(ctx_1.iconName3, ctx_1);
      r += '"></i>\n					<span class="tabbar-label">';
      r += c(ctx_1.buttonName3, ctx_1);
      r += '</span>\n				</a>\n				<a href="/suggestions/';
      r += c(ctx_1.userId, ctx_1);
      r += '/" class="tab-link panel-close">\n					<i class="fas ';
      r += c(ctx_1.iconName4, ctx_1);
      r += '"></i>\n					<span class="tabbar-label buttonName">';
      r += c(ctx_1.buttonName4, ctx_1);
      r += '</span>\n				</a>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  styleScoped: false
};