export default {
  data() {
    return {};
  },

  on: {
    pageBeforeIn() {
      var self = this; //var $$ = window.Dom7;
      //var m = this;
      //var cordova = cordova;

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      /*this.$f7.request.promise.get('https://apps.brvr.pt/teste.php', { 'id': 2134, 'type': 'list' }, 'json').then(function (res) {
      	self.$$("#menu_principal").html(res.data.html);
      })*/
      //console.log(self.$$);
      //console.log("aqui");

      this.$f7.request.promise.get('https://omeumenudigital.com/api/client/' + urlParams.get('id'), {}, 'json').then(function (res) {
        self.$$(".buttonName3").html(res.data.button_text_3);
        self.$$(".buttonName4").html(res.data.button_text_4);
        self.$$(".icon3").html('<i class="fas ' + res.data.button_icon_3 + '" id="icon3"></i>');
        self.$$(".icon4").html('<i class="fas ' + res.data.button_icon_4 + '" id="icon3"></i>');
        self.$$(".homepageBackground").css("background", "url('" + res.data.backgroundImage + "'");
        self.$$(".homepageBackground").css("background-size", "cover");
        self.$$(".homepageBackground").css("background-position", "center");
        self.$$(".homepageBackground").css("width", "100%");
        self.$$(".homepageBackground").css("height", "100%");
        self.$$(".clientLogo").attr("src", res.data.clientLogo);
        var result = [];

        for (var i in res.data.userLanguages) {
          result.push([i, res.data.userLanguages[i]]);
        }

        if (result.length > 1) {
          self.$$("#languageMenu").html(window.languageMenu);
        } //

      });
    }

  },
  id: 'bee07c901c',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div id="app">\n    <!-- Left panel with cover effect-->\n    <div class="panel panel-left panel-cover panel-init">\n      <div class="view">\n        <div class="page">\n          <div class="navbar">\n            <div class="navbar-bg"></div>\n            <div class="navbar-inner">\n              <div class="title">O meu Menu Digital</div>\n            </div>\n          </div>\n          <div class="page-content">\n            <div class="block">\n				<ul id="menu_principal"></ul>\n			</div>\n          </div>\n        </div>\n      </div>\n    </div>\n    <!-- Your main view, should have "view-main" class -->\n    <div class="view view-main view-init safe-areas" data-url="/"></div>\n  </div>\n';
      return r;
    }(this);
  },

  styleScoped: false
};