
import HomePage from '../pages/home.f7.html';
import HomeClientPage from '../pages/home.f7.html';
import AboutPage from '../pages/about.f7.html';
import FormPage from '../pages/form.f7.html';

import EmentaPage from '../pages/ementa.f7.html';
import WinesPage from '../pages/wines.f7.html';
import ProductPage from '../pages/product.f7.html';
import SuggestionsPage from '../pages/suggestions.f7.html';
import NotFoundPage from '../pages/404.f7.html';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

var routes = [
	{
		path: '/',
		async: function (routeTo, routeFrom, resolve, reject) {
			var router = this;
			var app = router.app;
			app.dialog.preloader('A carregar...')
			var userId = routeTo.params.userId;
			var menus = '';
			var res = "https://omeumenudigital.com/api/client/" + urlParams.get('id');
			
			var tempUri = window.languageUri;
			if ( tempUri == 'undefined' || !tempUri ) {
				window.languageUri = 'pt';
				window.language = 'pt';
				window.languageMenu = '';
				window.menuBottomStart = 'Inicio';
				window.menuBottomMenu = 'Menu';
				window.menuReady = false;
				window.oldLanguage = window.languageMenu;
			}


			app.request.promise.get(res, {	}, 'json').then(function (res) {
				menus = res.data.html;
				var countMenu = 0;

				if ( Object.keys(res.data.userLanguages).length == 0 ) {
					window.idiomas = 'visibility:hidden';
					menuReady == true;
				} else {
					if ( menuReady == false ) {
						window.idiomas = 'visibility:visible';
							window.languageMenu += '<a href="#" style="display: inline-block;padding:0 10px;" @click="checkLogin(\'pt\')" style="justify-content: flex-start;"><img src="static/images/pt.png" alt="Português" height="20"></a>';
							Object.keys(res.data.userLanguages).forEach(function(key){
								var value = res.data.userLanguages[key];
								window.languageMenu += '<a href="#" style="display: inline-block;padding:0 10px;" @click="checkLogin(\'' + value + '\')" style="justify-content: flex-start;"><img src="static/images/' + value + '.png" alt="' +  key + '" height="20"></a>';
								countMenu++;
							});
						if ( countMenu == Object.keys(res.data.userLanguages).length ) {
							menuReady = true;
						}
					}
				}
				setTimeout(function(){ app.dialog.close(); }, 400);
				resolve(
				{
					component: HomePage
				},
				{
					context: {
						menuBottomStart: window.menuBottomStart,
						menuBottomMenu: window.menuBottomMenu,
						language: window.language,
						menuLanguage: window.idiomas,
						languageMenu: window.languageMenu,
						user: menus,
						userId: urlParams.get('id')
					}
				})
			})
		},
	},
	/*{
		path: '/home/:userId/',
		
		async: function (routeTo, routeFrom, resolve, reject) {
			var router = thiaass;
			var app = router.app;
			app.dialog.preloader('A carregar...')
			var userId = routeTo.params.userId;
			var menus = '';
			app.request.promise.get('https://omeumenudigital.com/api/client/6b039f18ecbeb', {	}, 'json').then(function (res) {
				menus = res.data.html;
				setTimeout(function(){ app.dialog.close(); }, 400);
				resolve(
				{
					component: HomeClientPage,
				},
				{
					context: {
						user: menus,
						userID: '01'
					}
				})
			})
		},
		
	},*/
	{
		path: '/suggestions/:userId/',
		
		async: function (routeTo, routeFrom, resolve, reject) {
			var router = this;
			var app = router.app;
			app.dialog.preloader('A carregar...')
			var userId = routeTo.params.userId;
			window.tempUserId = userId;
			var menus = '';
			app.request.promise.get('https://omeumenudigital.com/api/' + window.languageUri + '/suggestions/' + userId, {	}, 'json').then(function (res) {
				menus = res.data.html;
				setTimeout(function(){ app.dialog.close(); }, 400);
				resolve(
				{
					component: SuggestionsPage,
				},
				{
					context: {
						menuBottomStart: window.menuBottomStart,
						menuBottomMenu: window.menuBottomMenu,
						language: window.language,
						menuLanguage: window.idiomas,
						languageMenu: window.languageMenu,
						products: menus,
						userId: userId,
						restaurantName: res.data.restaurantName,
						buttonName3: res.data.button_text_3,
						iconName3: res.data.button_icon_3,
						buttonName4: res.data.button_text_4,
						iconName4: res.data.button_icon_4,
						categoryName: res.data.button_text_4
					}
				})
			})
		},
		
	},
	{
		path: '/about/',
		component: AboutPage,
	},
	{
		path: '/form/',
		component: FormPage,
	},
	{
		//path: '/ementa/:userId/:menuId/',
		path: '/ementa/:userId/:typeId/:menuId/',
		stackPages: false,
		async: function (routeTo, routeFrom, resolve, reject) {
		//keepAlive: false,
		//redirect: function (routeTo, resolve, reject) {
			var router = this;
			var app = router.app;
			app.dialog.preloader('A carregar...')

			var userId = routeTo.params.userId; 
			var typeId = routeTo.params.typeId;
			var menuId = routeTo.params.menuId;

			window.tempUserId = userId;
			window.tempTypeId = typeId;
			window.tempMenuId = menuId;

			var products = '';
			var url = '';
			
			if ( typeId ) {
				url = url + '/' + typeId;
			}
			if ( userId ) {
				url = url + '/' + userId;
			}
			if ( menuId ) {
				if ( menuId > 0 ) {
					url = url + '/' + menuId
				}
			}			

			app.request.promise.get('https://omeumenudigital.com/api/'+ window.languageUri + url, { }, 'json').then(function (res) 
			{
				products = res.data.html;
				var tab_1 = '';
				var tab_2 = '';
				if ( res.data.wine == false ) {
					var tab_1 = 'tab-link-active';
				} else {
					var tab_2 = 'tab-link-active';
				}
				var styleHome = "";
				var styleBack = "";
				if ( res.data.level == true ) {
					styleBack = 'style="display:none"';
				} else {
					styleHome = 'style="display:none"';
				}
				setTimeout(function(){ app.dialog.close(); }, 400);
				resolve(
				{
					component: EmentaPage,
					keepAlive: false,
				},
				{
					context: {
						menuBottomStart: window.menuBottomStart,
						menuBottomMenu: window.menuBottomMenu,
						language: window.language,
						menuLanguage: window.idiomas,
						languageMenu: window.languageMenu,
						products: products,
						userId: userId,
						restaurantName: res.data.restaurantName,
						categoryName: res.data.categoryName,
						styleHome: styleHome,
						styleBack: styleBack,
						tab_1 : tab_1,
						tab_2 : tab_2,
						buttonName3: res.data.button_text_3,
						iconName3: res.data.button_icon_3,
						buttonName4: res.data.button_text_4,
						iconName4: res.data.button_icon_4,
					}
				})
			})
		},
	},
	{
		//path: '/ementa/:userId/:menuId/',
		path: '/wines/:userId/:categoryId/',
		async: function (routeTo, routeFrom, resolve, reject) {
			var router = this;
			var app = router.app;
			app.dialog.preloader('A carregar...')

			var userId = routeTo.params.userId; 
			var categoryId = routeTo.params.categoryId;

			window.tempUserId = userId;
			window.tempcategoryId = categoryId;

			var products = '';
			var url = '';

			if ( userId ) {
				url = url + '/' + userId;
			}
			if ( categoryId ) {
				if ( categoryId > 0 ) {
					url = url + '/' + categoryId
				}
			}			

			app.request.promise.get('https://omeumenudigital.com/api/'+ window.languageUri + '/wine' + url, { }, 'json').then(function (res) 
			{
				products = res.data.html;
				var tab_1 = '';
				var tab_2 = '';
				if ( res.data.wine == false ) {
					var tab_1 = 'tab-link-active';
				} else {
					var tab_2 = 'tab-link-active';
				}
				setTimeout(function(){ app.dialog.close(); }, 400);
				resolve(
				{
					component: WinesPage,
				},
				{
					context: {
						menuBottomStart: window.menuBottomStart,
						menuBottomMenu: window.menuBottomMenu,
						language: window.language,
						menuLanguage: window.idiomas,
						languageMenu: window.languageMenu,
						products: products,
						userId: userId,
						restaurantName: res.data.restaurantName,
						categoryName: res.data.categoryName,
						tab_1 : tab_1,
						tab_2 : tab_2,
						buttonName3: res.data.button_text_3,
						iconName3: res.data.button_icon_3,
						buttonName4: res.data.button_text_4,
						iconName4: res.data.button_icon_4,
					}
				})
			})
		},
	},
	{
		path: '/product/:userId/:productId/',
		async: function (routeTo, routeFrom, resolve, reject) {
			var router = this;
			var app = router.app;
			app.dialog.preloader('A carregar...')

			var userId = routeTo.params.userId;
			var productId = routeTo.params.productId;

			window.tempUserId = userId;
			window.tempProductId = productId;

			var products = '';
//			app.request.promise.get('https://omeumenudigital.com/api/category/6b039f18ecbeb', { 'userId': userId, type: typeId , menuId: menuId }).then(function (res) 
			var url = '';
			
			if ( userId ) {
				url = url + '/' + userId;
			}
			if ( productId ) {
				if ( productId > 0 ) {
					url = url + '/' + productId
				}
			}	
			app.request.promise.get('https://omeumenudigital.com/api/' + window.languageUri + '/product' + url, { }, 'json').then(function (res) 
			{
				products = res.data.html;
				var tab_1 = '';
				var tab_2 = '';
				if ( res.data.wine == false ) {
					var tab_1 = 'tab-link-active';
				} else {
					var tab_2 = 'tab-link-active';

				}
				setTimeout(function(){ app.dialog.close(); }, 400);
				resolve(
				{
					component: ProductPage,
				},
				{
					context: {
						menuBottomStart: window.menuBottomStart,
						menuBottomMenu: window.menuBottomMenu,
						language: window.language,
						menuLanguage: window.idiomas,
						languageMenu: window.languageMenu,
						products: products,
						userId: userId,
						restaurantName: res.data.restaurantName,
						categoryName: res.data.categoryName,
						tab_1 : tab_1,
						tab_2 : tab_2,
						buttonName3: res.data.button_text_3,
						iconName3: res.data.button_icon_3,
						buttonName4: res.data.button_text_4,
						iconName4: res.data.button_icon_4,
					}
				})
			})
		},
	},
	{
		path: '(.*)',
		component: NotFoundPage,
	},
];

export default routes;