const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
export default {
  methods: {
    checkLogin(selectedLanguage) {
      window.languageUri = selectedLanguage;
      window.language = selectedLanguage;

      if (selectedLanguage == 'pt') {
        window.menuBottomStart = 'Inicio';
        window.menuBottomMenu = 'Menu';
      } else if (selectedLanguage == 'en') {
        window.menuBottomStart = 'Home';
        window.menuBottomMenu = 'Menu';
      } else if (selectedLanguage == 'fr') {
        window.menuBottomStart = 'Accueil';
        window.menuBottomMenu = 'Menu';
      } else if (selectedLanguage == 'es') {
        window.menuBottomStart = 'Entrada';
        window.menuBottomMenu = 'Menú';
      }

      var f7 = this.$f7;

      if (this.$app.views.current.id == "view_main") {
        this.$app.views.main.router.refreshPage();
      }

      var destiny = ''; //var userId = urlParams.get('id');

      destiny = '/';
      f7.views.main.router.navigate(destiny);
    }

  },
  id: 'c8da99c598',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n	<div class="page" data-name="home">\n		<!-- Top Navbar -->\n		<div class="navbar">\n			<div class="navbar-bg"></div>\n			<div class="navbar-inner">\n				<div class="left">\n					<a href="/?';
      r += c(ctx_1.userId, ctx_1);
      r += '" class="link icon-only panel-close" style="display:none;">\n						<i class="material-icons">home</i>\n						<span class="tabbar-label"></span>\n					</a>\n					<a href="#" class="link icon-only panel-open" data-panel="left" style="display:none;">\n						<i class="icon f7-icons if-not-md">menu</i>\n						<i class="icon material-icons if-md">menu</i>\n					</a>\n				</div>\n				<div class="title sliding">O meu menu digital</div>\n				<div class="right" style="';
      r += c(ctx_1.menuLanguage, ctx_1);
      r += '">\n					<div class="menu-item menu-item-dropdown">\n						<div class="menu-item-content"><img src="static/images/';
      r += c(ctx_1.language, ctx_1);
      r += '.png" alt="';
      r += c(ctx_1.languageDescription, ctx_1);
      r += '" height="20"></div>\n					</div>\n				</div>\n			</div>\n		</div>\n		<!-- Scrollable page content-->\n		<div class="page-content">\n			<div class="homepageBackground">\n				<div class="logotipo">\n					<img src="static/images/spacer.gif" class="clientLogo">\n				</div>\n			</div>\n		</div>\n		<div style="width: 95%; position: fixed; top: 75%; left: 0; z-index: 2;text-align: center;">\n			';
      r += c(ctx_1.languageMenu, ctx_1);
      r += '\n		</div>\n		<div class="toolbar toolbar-bottom tabbar tabbar-labels">\n			<div class="toolbar-inner">\n				<a href="/" class="tab-link panel-close">\n					<i class="fas fa-home"></i>\n					<span class="tabbar-label">';
      r += c(ctx_1.menuBottomStart, ctx_1);
      r += '</span>\n				</a>\n				<a href="/ementa/';
      r += c(ctx_1.userId, ctx_1);
      r += '/category/0/" class="tab-link panel-close">\n					<i class="fas fa-book-open"></i>\n					<span class="tabbar-label">';
      r += c(ctx_1.menuBottomMenu, ctx_1);
      r += '</span>\n				</a>\n				<a href="/wines/';
      r += c(ctx_1.userId, ctx_1);
      r += '/0/" class="tab-link panel-close ';
      r += c(ctx_1.tab_2, ctx_1);
      r += '">\n					<span class="icon3"></span>\n					<span class="tabbar-label buttonName3"></span>\n				</a>\n				<a href="/suggestions/';
      r += c(ctx_1.userId, ctx_1);
      r += '/" class="tab-link panel-close">\n					<span class="icon4"></span>\n					<span class="tabbar-label buttonName4"></span>\n				</a>\n			</div>\n		</div>\n	</div>\n';
      return r;
    }(this);
  },

  styleScoped: false
};